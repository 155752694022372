<script setup>
import VueApexCharts from "vue3-apexcharts";
import { debounce } from "lodash-es";

const props = defineProps({
  languages: {
    type: Array,
    required: true,
    default: () => [],
  },
  totalLangClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const seriesData = computed(() => props.languages?.map((e) => e.clicks));
const chartData = computed(() => props.languages?.map((e) => e.lang));

const series = ref(seriesData);

const chartOptions = computed(() => ({
  chart: {
    type: "donut",
  },
  title: {
    text: "Languages",
    align: "center",
    margin: 20,
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,

          value: {
            fontSize: "16px",
            offsetY: 1,
            formatter: function (value) {
              return ((value / props.totalLangClicks) * 100).toFixed(1) + "%";
            },
          },
        },
      },
    },
  },
  labels: chartData.value,

  legend: {
    show: true,
    position: "bottom",
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return ((value / props.totalLangClicks) * 100).toFixed(1) + "%";
      },
    },
  },
}));

const chartRef = ref(null);

// Debounce the update function
const debouncedUpdateData = debounce(() => {
  chartRef.value?.updateSeries(seriesData.value);
  chartRef.value?.updateOptions({
    labels: chartData.value,
  });
}, 300);

watch(() => props.languages, debouncedUpdateData, { immediate: true });

// Cleanup on unmount
onUnmounted(() => {
  debouncedUpdateData.cancel();
});
</script>

<template>
  <div
    class="border bg-white dark:bg-[#181818] dark:border-[#181818] rounded-xl p-4 mr-0 mb-5 overflow-hidden flex items-center"
  >
    <div
      v-if="totalLangClicks === 0"
      class="items-center flex flex-col justify-center h-72 w-full"
    >
      <SvgoNoTopLinks class="mx-auto text-[8rem] pointer-events-none" />
      <h1 class="font-bold text-md mt-5 dark:text-gray-50">No language data found!</h1>
    </div>
    <VueApexCharts
      v-else
      ref="chartRef"
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<style></style>
