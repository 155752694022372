<script setup>
import { SvgArrow, SvgGlobe, SvgComputer } from "#components";

const route = useRoute();
const currentPath = computed(() => {
  const pathSegments = route.path.split("/");
  return pathSegments[pathSegments.length - 1];
});

const tabs = {
  clicks: { label: "Clicks", svg: SvgArrow },
  locations: { label: "Locations", svg: SvgGlobe },
  "traffic-sources": { label: "Traffic Sources", svg: SvgComputer },
};

const activeTab = computed(() => tabs[currentPath.value] || {});
</script>
<template>
  <div class="flex items-center">
    <component :is="activeTab.svg" />

    <h1 class="font-bold text-lg dark:text-slate-50">
      {{ activeTab.label || "" }} Overview
    </h1>
  </div>
</template>

<style lang="postcss">
.dark-analytics-svg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(5553%)
    hue-rotate(269deg) brightness(95%) contrast(107%) !important;
}
</style>
