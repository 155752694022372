<script setup></script>

<template>
  <div>
    <OverviewSideColBasicData />
    <TableRecentActivities />
  </div>
</template>

<style></style>
