<script setup>
import { storeToRefs } from "pinia";
import { useActivitiesApi } from "~/composables/useActivitiesApi";
import { useAnalyticsStore } from "~/store/useAnalyticsStore.js";
import { humanReadableRange } from "~/utils/dateTime";
import moment from "moment";

const { data, loading, fetchActivitiesApi } = useActivitiesApi();

const isdataEmpty = computed(() => {
  return !data.value || Object.keys(data.value).length === 0;
});

const { selectedDateRange } = storeToRefs(useAnalyticsStore());

const duration = computed(
  () =>
    `Acitivity in ${humanReadableRange(
      selectedDateRange.value.startDate,
      selectedDateRange.value.endDate
    )}`
);

const fetchData = () =>
  fetchActivitiesApi({
    start: selectedDateRange.value.startDate,
    end: selectedDateRange.value.endDate,
    limit: 10,
    shouldFormat: false,
  });

onMounted(fetchData);

watch(selectedDateRange, fetchData);
</script>
<template>
  <div
    class="border bg-white dark:bg-[#181818] dark:border-[#181818] rounded-xl p-2 mr-0 overflow-hidden relative"
  >
    <div v-if="loading" class="loading-overlay">
      <LoadersRingLoader />
    </div>

    <h1 v-if="!isdataEmpty" class="font-bold my-4 dark:text-slate-50 text-center">
      {{ duration }}
    </h1>

    <div
      class="overflow-y-auto h-96 remove-scroll dark:text-slate-50"
      :class="{ 'h-72': !isdataEmpty }"
    >
      <div v-if="isdataEmpty" class="h-full items-center flex justify-center">
        <div>
          <SvgoNoActivity class="mx-auto text-[12rem] pointer-events-none" />
          <h1 class="font-bold mt-5 dark:text-gray-50 text-center">
            No Recent activity recorded!
          </h1>
        </div>
      </div>
      <ol v-else class="relative border-l border-gray-200 dark:border-gray-700 m-1">
        <li v-for="(activity, index) in data" :key="index" class="mb-10 ml-4 text-left">
          <div
            class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
          ></div>
          <time
            class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
          >
            {{ moment(activity.timestamp).format("MMM Do, h:mm a") }}</time
          >
          <p
            class="mb-4 text-base text-gray-700 dark:text-gray-400 font-medium break-all"
          >
            {{ activity.action }}
            <span class="text-primary">{{ activity.user__email }}</span>
          </p>
        </li>
      </ol>
    </div>

    <div v-if="!isdataEmpty" class="flex items-center">
      <div class="w-full pt-2 border-t text-center">
        <NuxtLink
          to="/dashboard/activities"
          class="my-2 w-full font-medium text-primary dark:text-slate-50 z-10 cursor-pointer"
          >See All</NuxtLink
        >
      </div>
    </div>
  </div>
</template>

<style></style>
