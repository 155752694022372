<script setup>
import { useAnalyticsStore } from "~/store/useAnalyticsStore";
import { storeToRefs } from "pinia";
import moment from "moment";

const analyticsStore = useAnalyticsStore();
const { selectedDateRange } = storeToRefs(analyticsStore);
const { updateSelectedDateRange } = analyticsStore;

const formattedDateRange = computed(() => ({
  start: moment(selectedDateRange.value?.startDate?._d).format("DD MMM YYYY"),
  end: moment(selectedDateRange.value?.endDate?._d).format("DD MMM YYYY"),
}));
</script>

<template>
  <CustomDateRangeSelector
    :selectedDateRange="formattedDateRange"
    @updateDateRange="updateSelectedDateRange"
  />
</template>

<style></style>
