<script setup></script>

<template>
  <div
    class="grid grid-cols-3 justify-center bg-white dark:bg-[#181818] border dark:border-none rounded-2xl px-8 py-7 gap-4 items-center"
  >
    <div class="col-span-1">
      <OverviewHeaderTitle />
    </div>
    <div class="lg:col-span-1 col-span-2 flex justify-center">
      <!-- <OverviewPeriodSelectButton /> -->
      <OverviewDateRangeSelector />
    </div>
    <div class="lg:col-span-1 md:col-span-0">
      <!-- <OverviewHeaderTimer /> -->
    </div>
  </div>
</template>

<style lang="postcss"></style>
