<script setup>
import { useAnalyticsApi } from "~/composables/useAnalyticsApi";
import { useAnalyticsStore } from "~/store/useAnalyticsStore";
import { storeToRefs } from "pinia";

const { data: analyticsData, loading, fetchBasicData } = useAnalyticsApi();
const { selectedDateRange } = storeToRefs(useAnalyticsStore());

const formatCount = (count) => (isNaN(count) ? "-" : count?.toLocaleString());

const fetchData = () => fetchBasicData({ collectionId: null, linkId: null });

onMounted(fetchData);

watch([selectedDateRange], fetchData);
</script>
<template>
  <div class="relative rounded-xl overflow-hidden bg-white">
    <div v-if="loading" class="loading-overlay">
      <LoadersRingLoader />
    </div>

    <CardAnalyticsStats
      class="mb-4"
      :header="'Smartlnks Created'"
      :count="formatCount(analyticsData?.total_smartlnks_created)"
      :iconBg="'bg-blue-100'"
      :iconColor="'text-blue-500'"
      :showActiveCount="true"
      :activeCount="analyticsData?.active_smartlnks"
      :showTodayCount="true"
      :todayCount="analyticsData?.total_smartlnks_created_today"
      :startDateTime="selectedDateRange?.startDate"
      :endDateTime="selectedDateRange?.endDate"
    />

    <CardAnalyticsStats
      class="mb-4"
      :header="'SmartDomains Created'"
      :count="formatCount(analyticsData?.total_smartdomains_created)"
      :iconBg="'bg-blue-100'"
      :iconColor="'text-blue-500'"
      :showActiveCount="true"
      :activeCount="analyticsData?.active_smartDomains"
      :showTodayCount="true"
      :todayCount="analyticsData?.total_smartdomains_created_today"
      :startDateTime="selectedDateRange?.startDate"
      :endDateTime="selectedDateRange?.endDate"
    />
    <ChartLanguageSummary
      :languages="analyticsData?.lang"
      :totalLangClicks="analyticsData?.total_lang_clicks"
    />
  </div>

  <TableTopLinks
    :links="analyticsData?.top_smartlnks"
    :header="'Top SmartLnks'"
    :emptyPlaceholder="'No top smartlnks yet!'"
    :tableCol1Header="'SmartLnk'"
  />
  <TableTopLinks
    :links="analyticsData?.top_smartDomains"
    :header="'Top SmartDomains'"
    :emptyPlaceholder="'No top smartdomains yet!'"
    :tableCol1Header="'SmartDomain'"
  />
</template>

<style></style>
